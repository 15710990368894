<template>
  <div>
    <CCard class="p-4">
      <CRow>
        <CCol lg="6">
          <h5>Personnel ledger</h5>
        </CCol>
        <CCol lg="6">
          
          <CButton
            v-if="config.getPermission('personnel_ledger').view"
            size="sm"
            style="margin-right: 10px"
            color="btn btn-outline-info"
            @click="viewPayment()"
            class="float-lg-right"
          >
            <i class="fa fa-list"></i> Deduction History</CButton
          >
          <CButton
            v-if="config.getPermission('personnel_ledger').download"
            size="sm"
            style="margin-right: 10px"
            color="btn btn-outline-primary"
            @click="download()"
            class="float-lg-right"
          >
            <i class="fa fa-download"></i> Download</CButton
          >
        </CCol>
      </CRow>
      <hr />
      <CRow v-for="(item,key) in dataLedgerInfo" :key="key" class="p-2 mb-1 bg-light text-dark rounded">        
        
        <CCol lg="3">
          <span >
            <h2 class="mb-0 text-primary">{{item.employee_name}}</h2>
            <p>
              <em>{{item.personnel_type}}</em><br />
              <strong style="text-transform:uppercase; font-size:12px;color:green">{{item.status}}</strong>
            </p>
          </span>
        </CCol>
        <CCol lg="4">
          <p style="margin-top: 10px">
           
            <strong style="margin-right: 122px">CA Balance:</strong>
               {{item.personnel_ledger_CAB | number_format('currency')}}
               <br/>
            <strong style="margin-right: 89px">Charges Balance:</strong> {{item.personnel_ledger_CB | number_format('currency')}}<br/>
            <strong style="margin-right: 25px">Other Deductions Balance:</strong> {{item.personnel_ledger_OtherDeductionsBalance | number_format('currency')}}
           
          </p>
        </CCol>
  
        <CCol lg="3">
          <p style="margin-top: 10px">
            <strong style="margin-right: 45px">SSS Loan Balance:</strong> {{item.personnel_ledger_SSSBalance | number_format('currency')}}
            <br />
            <strong style="margin-right: 20px">Pagibig Loan Balance:</strong> {{item.personnel_ledger_PagibigBalance | number_format('currency')}}
          </p>
        </CCol>
        <CCol lg="2">
          <span >
            <h5 class="mb-0 text-primary">Total Balance</h5>
            <h3>
              {{item.personnel_ledger_Total | number_format('currency')}}
            </h3>
          </span>
          <!-- <p>
            <h5>
              <strong
              ><span style="margin-right: 35px">Total Balance</span> <br>
              {{item.personnel_ledger_Total | number_format('currency')}}
            </strong>
          </h5>
          </p>    -->
        </CCol>      
      </CRow>
      <br/>
      <CRow>
        <CCol lg="12">
          <CRow>
            <CCol lg="9" class="form-inline">
              <div>
              <h6>Date From</h6>
              <div class="form-inline">
                <Datepicker
                  input-class="form-control bg-white"
                  v-model="date_from"
                  
                >
                </Datepicker>
                <i class="fa fa-calendar ml-2"></i>
              </div>
            </div>
              <div class="ml-3">
              <h6>Date To</h6>
              <div class="form-inline">
                <Datepicker
                  input-class="form-control bg-white"
                  v-model="date_to"
                  
                >
                </Datepicker>
                <i class="fa fa-calendar ml-2"></i>
              </div>
            </div>

              <div class="ml-3 mt-4">
							<CButton 
							size="sm"
							color="info" @click="filterList" 
							>
							<i class="fa fa-filter"></i> Filter
							</CButton>	

						</div>	
            </CCol>	
            <CCol lg="3" class="mt-3">
            <CButton
            v-if="config.getPermission('personnel_ledger').create"
            size="sm"
            style="margin-right: 10px"
            color="success"
            @click="addModal()"
            class="float-lg-right"
            ><i class="fa fa-plus"></i>Add Entry
          </CButton>
          <CButton
            v-if="config.getPermission('personnel_ledger').create"
            size="sm"
            style="margin-right: 10px"
            color="danger"
            @click="adddDeduction()"
            class="float-lg-right"
            ><i class="fa fa-minus"></i>Add Deduction
          </CButton>
            </CCol>
        
          </CRow>
        </CCol>
        
      </CRow>
      <br>
      <CCol lg="12" md="12" sm="12">
        <div class="form-group mt-4">
          <CInputRadioGroup
            @update:checked="typeChanged"
            :options="[
              {
                value: 'all',
                label: 'All',
              },
              {
                value: 'charges',
                label: 'Charges',
              },
              {
                value: 'cash_advance',
                label: 'Cash Advance',
              },
              {
                value: 'pagibig_loan',
                label: 'Pag-ibig Loan',
              },
              {
                value: 'sss_loan',
                label: 'SSS Loan',
              },
              {
                value: 'other_deductions',
                label: 'Other Deductions',
              },
            ]"
            :checked.sync="dataParams.entry_type"
            :inline="true"
            required="true"
            id="filter_type"
          />
        </div>
      </CCol>
      <CRow>
      
        <CCol lg="12">
          <div class="table-responsive-sm table-responsive-md">
            <CDataTable
             pagination
             :loading="isLoading"
              :items="dataList.data"
              :fields="fields"
              hover
              outlined
              :tableFilter='{ placeholder: "Type, amount, date, remarks" }'
              itemsperPage="10"
							itemsPerPageSelect
  
            >
              <template #status="{ item }">
                <td
                  style="padding: 5px; width: 100px; text-transform: uppercase"
                >
                  <CBadge
                    style="margin-top: 10px"
                    :color="getBadge(item.status)"
                    >{{ item.status }}</CBadge
                  >
                </td>
              </template>
              <template #action="{ item }">
                <td style="padding: 5px; width: 100px">
                  <CDropdown toggler-text="Select" size="sm" color="info">
                   
                    <CDropdownItem
                      v-if="
                        config.getPermission('personnel_ledger').update
                      "
                      @click="updateModal(item)"
                      ><i class="fa fa-pencil-square-o">
                        Update
                      </i></CDropdownItem
                    >
                    <CDropdownItem
                      v-if="config.getPermission('personnel_ledger').delete"
                      @click="deleteItem(item)"
                      ><i class="fa fa-trash"> Delete</i></CDropdownItem
                    >
                  </CDropdown>
                </td>
              </template>
            </CDataTable>
            <!-- <CPagination
              :active-page.sync="currentPage"
              :pages="Math.ceil(dataList.total / 20)"
              :activePage="currentPage"
              @update:activePage="updatePage"
            /> -->
          </div>
        </CCol>
      </CRow>

     
    </CCard>
    <!-- ADD ENTRY-->
    <CModal
      :title="title"
      :show.sync="formModal"
      @close="closeModal()"
      color="info"
      size="lg"
     
    >
      <form @submit.prevent="submit">
        
          <CRow>
            <CCol class="col-md-12" v-if="!canCashAdvance">
              <div class="alert alert-warning" role="alert">
                    <h4><i class="fa fa-warning"></i> Cash advance transaction warning!</h4>
                    <small class="mb-0">
                        <em>This personnel has less than {{ '3000' | number_format('currency') }} salary for the last 14 days. 
                        </em>
                     </small>
                    <hr class="my-1">
                    <small>Computed salary from {{ salary_start_date }} to {{ salary_end_date }} : <strong>{{ salary | number_format('currency') }}</strong></small>
                </div>
            </CCol>
            <CCol class="col-md-12" v-if="caHasError">
              <div class="alert alert-danger" role="alert">
                   <h5><i class="fa fa-warning"></i> Warning! The maximum allowable amount has been consumed.</h5>
                  
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol class="col-md-12">
              <div class="form-group">
                <small for="entry_type"
                  >Type<span class="text-danger"> *</span>
              </small>
                <CInputRadioGroup
                  :options="[
                    {
                      value: 'cash_advance',
                      label: 'Cash Advance',
                      // props: { required: 'true', disabled : !this.canCashAdvance }  <!-- Gusto nila warning nlng daw. Maka cash advance jpn bsan less than 3k iyang computed salary for last 14 days -->
                      props: { required: 'true'}
                    },
                    {
                      value: 'charges',
                      label: 'Charges',
                      props: { required: 'true' }
                    },
                    {
                      value: 'sss_loan',
                      label: 'SSS Loan',
                      props: { required: 'true' }
                    },
                    {
                      value: 'pagibig_loan',
                      label: 'Pag-ibig Loan',
                      props: { required: 'true' }
                    },
                    {
                      value: 'other_deductions',
                      label: 'Other Deductions',
                      props: { required: 'true' }
                    },
                  ]"
                  :checked.sync="personnelLedgerParam.entry_type"
                  :inline="true"
                  required="true"
                  class="mb-2"
                />
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol lg="12" >
              <small
                >Amount
                <span class="text-danger">* </span>
                <!-- <strong class="float-right" v-if="personnelLedgerParam.entry_type == 'cash_advance'">
                  Allowable amount: <span class="text-danger">{{ computeAllowedCA | number_format('currency') }}</span>
                </strong> -->
              </small>
              <CInput type="text" v-model="personnelLedgerParam.amount" 
              @keypress="isNumber($event)"
              class="mb-0" 
              required="true"/>
            </CCol>
          </CRow>
            <!-- Gusto nila warning nlng daw. Maka cash advance jpn bsan lampas nag 2k -->
            <!-- <CCol lg="12" >
              <small
                >Amount
                <span class="text-danger">* </span>
                <strong class="float-right" v-if="personnelLedgerParam.entry_type == 'cash_advance'">
                  Allowable amount: <span class="text-danger">{{ computeAllowedCA | number_format('currency') }}</span>
                </strong>
              </small>
              <CInput type="text" v-model="personnelLedgerParam.amount" 
              @keypress="isNumber($event)"
              class="mb-0" 
              :class="{'has-error' : caHasError} "
              required="true"/>
              <div v-show="caHasError" >
                <small class="text-danger">
                  Amount should not be greater than the allowable amount.
                </small>
               </div>
            </CCol>
          </CRow> -->


          <CRow>
            <CCol lg="12">
              <small
                >Date
                <span class="text-danger">* </span></small >
              <Datepicker
                input-class="form-control bg-white"
                :typeable="true" 
                class="mb-2"  
                v-model="personnelLedgerParam.entry_date"
                required
              >
              </Datepicker>
            </CCol>
          </CRow>

          <CRow>
            <CCol lg="12">
              <small class="mr-2">Remarks</small>
              <textarea
                :disabled="is_disabled"
                class="form-control mb-2"
                v-model="personnelLedgerParam.remarks"
              ></textarea>
            </CCol>
          </CRow>
      
        <div slot="footer" class="w-100 mt-3">
        
          <template>
            <CButton
              type="submit"
              style="border-radius: 0.2rem; color: white"
              color="info"
              class="ml-1 mr-1 float-right"
              size="sm"              
              
            >
              Save
            </CButton>
            <!-- <a href="javascript:;" class="btn btn-sm btn-info disabled ml-1 mr-1 float-right" v-else>Save</a> -->
           
          </template>
          <CButton
            style="border-radius: 0.2rem; color: white"
            color="danger"
            class="ml-1 mr-1 float-right"
            @click="closeModal()"
            size="sm"
          >
            Cancel
          </CButton>
        </div>
      </form>
      <div slot="footer" class="w-100"></div>
    </CModal>

    <!-- DEDUCTION -->
    <CModal
      :title="title"
      :show.sync="deductionModal"
      @close="closeModal()"
      color="info"
      size="lg"
      
    >
      <form @submit.prevent="submit">
     
          <CRow>
            <CCol class="col-md-12">
              <div class="form-group">
                <label style="font-size: 12px;" for="charges_type"
                  >Type<span class="text-danger"> *</span>
                </label>
                <CInputRadioGroup
                  :options="[
                    {
                      value: 'cash_advance',
                      label: 'Cash Advance',
                      props: { required: 'true' }
                    },
                    {
                      value: 'charges',
                      label: 'Charges',
                      props: { required: 'true' }
                    },
                    {
                      value: 'sss_loan',
                      label: 'SSS Loan',
                      props: { required: 'true' }
                    },
                    {
                      value: 'pagibig_loan',
                      label: 'Pag-ibig Loan',
                      props: { required: 'true' }
                    },
                    {
                      value: 'other_deductions',
                      label: 'Other Deductions',
                      props: { required: 'true' }
                    },
                  ]"
                  :checked.sync="personnelLedgerParam.entry_type"
                  :inline="true"
                  required="true"
                  
                />
              </div>
            </CCol>
          </CRow>
          
				<CRow>
				
				
				</CRow>
				
          <CRow>
            <CCol lg="12">
              <div class="form-group">
              <label style="font-size: 12px;"
                >Deduction Amount
                <span class="text-danger">* </span></label>
              <CInput type="text" v-model="personnelLedgerParam.amount"
                @keypress="isNumber($event)" 
                required="true" class="mb-0" :class="{'has-error' : hasError}"/>
              <div v-show="hasError">
                <small class="text-danger" v-if="personnelLedgerParam.entry_type == 'cash_advance'">
                  Deduction amount should not be greater than with cash advance balance.
                </small>
                <small class="text-danger" v-if="personnelLedgerParam.entry_type == 'charges'">
                  Deduction amount should not be greater than with charges balance.
                </small>
                <small class="text-danger" v-if="personnelLedgerParam.entry_type == 'sss_loan'">
                  Deduction amount should not be greater than with sss loan balance.
                </small>
                <small class="text-danger" v-if="personnelLedgerParam.entry_type == 'pagibig_loan'">
                  Deduction amount should not be greater than with pagibig loan balance.
                </small>
                <small class="text-danger" v-if="personnelLedgerParam.entry_type == 'other_deductions'">
                  Deduction amount should not be greater than with other deduction balance.
                </small>

              </div>
              <!-- <small class="text-danger" v-show="hasError">
                {{ personnelLedgerParam.entry_type == 'cash_advance' ? 'Cash advance' : 'Charges' }}
                 deduction amount should not be greater than with the balance.</small> -->
            </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol lg="12">
              <small
                >Date
                <span class="text-danger">* </span></small >
              <Datepicker
                input-class="form-control bg-white"
                :typeable="true" 
                class="mb-2"  
                v-model="personnelLedgerParam.entry_date"
                required
              >
              </Datepicker>
            </CCol>
          </CRow>
          <CRow>
            <CCol lg="12">
              <div class="form-group">
              <label style="font-size: 12px;">Remarks</label>
              <textarea
                class="form-control"
                v-model="personnelLedgerParam.remarks"
              ></textarea>
            </div>
            </CCol>
          </CRow>
      
        <div slot="footer" class="w-100 mt-3">
         
          <template >
            <CButton
              type="submit"
              style="border-radius: 0.2rem; color: white"
              color="info"
              class="ml-1 mr-1 float-right"
              size="sm"
              v-if="!hasError"
            >
              Save
            </CButton>
            <a href="javascript:;" class="btn btn-sm btn-info disabled ml-1 mr-1 float-right" v-else>Save</a>
            
          </template>
          <CButton
            style="border-radius: 0.2rem; color: white"
            color="danger"
            class="ml-1 mr-1 float-right"
            @click="closeModal()"
            size="sm"
          >
            Close
          </CButton>
        </div>
      </form>
      <div slot="footer" class="w-100"></div>
    </CModal>
  </div>
</template>
<script>
import config from "../../config.js";
import axios from "../../axios";
import Swal from "sweetalert2";
import Search from "./search";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import moment from "moment";

export default {
  mounted() {
    // this.getEntryListFilter();
    this.defaultDateFrom();
    this.getEntryList();
    this.getPersonnel();
    this.getLedgerInfo();        
  },
  filters: {
    formatDate(val) {
      if (val) return moment.unix(val).format("MMMM DD, YYYY");
      return "";
    },
  },
  watch: {
    
    
  },
  computed: {
    canCashAdvance() {
      if(this.salary < 3000) {
        return false;
      }
      return true;
    },
    computeAllowedCA() {
        let balance = this.dataLedgerInfo[0].personnel_ledger_CAB ?? 0;
        return 2000 - balance;
    },
    is_disabled() {
      return this.mode.view;
    },
    checkFilter() {
      return this.dataParams.asset_type;
    },
    caHasError() {
      if (this.personnelLedgerParam.entry_type != 'cash_advance') return false;

      let inputted = this.personnelLedgerParam.amount ?? 0;
      if(parseFloat(inputted) > parseFloat(this.computeAllowedCA)) {
        return true;
      }

      return false;
    },
    hasError() {
        let type = this.personnelLedgerParam.entry_type;
        let inputted = this.personnelLedgerParam.amount ?? 0;
        let balance = 0;
        
        if(!this.dataLedgerInfo.length) return false;

        if(type == 'cash_advance') { //cash advance
          balance = this.dataLedgerInfo[0].personnel_ledger_CAB ?? 0;
          if(parseFloat(inputted) > parseFloat(balance)) {
            return true;
          }

          return false;
        }
        else if(type == 'charges') { //charges
          balance = this.dataLedgerInfo[0].personnel_ledger_CB ?? 0;
          if(parseFloat(inputted) > parseFloat(balance)) {
            return true;
          }
          return false;
        }
        else if(type == 'pagibig_loan') { //pagibig
          balance = this.dataLedgerInfo[0].personnel_ledger_PagibigBalance ?? 0;
          if(parseFloat(inputted) > parseFloat(balance)) {
            return true;
          }
          return false;
        }
        else if(type == 'sss_loan') { //sss loan
          balance = this.dataLedgerInfo[0].personnel_ledger_SSSBalance ?? 0;
          if(parseFloat(inputted) > parseFloat(balance)) {
            return true;
          }
          return false;
        }
        else if(type == 'other_deductions') { //other deduction
          balance = this.dataLedgerInfo[0].personnel_ledger_OtherDeductionsBalance ?? 0;
          if(parseFloat(inputted) > parseFloat(balance)) {
            return true;
          }
          return false;
        }
    }
  },
  data() {
    return {
      title: '',
      charge_date_to:null,
      charge_date_from:null,
      flag: 0,
      isLoading: false,
      config,      
      dataParams: {
        entry_type: "all",
      },
      formModal: false,
      deductionModal: false,
      dataLedgerInfo: [],
      dataList: [],
      depotName: "",
      fields: [
        {
          key: "entry_type",
          label: "Type",
        },
        {
          key: "amount",
          label: "Amount",
        },
        {
          key: "entry_date",
          label: "Date",
        },

        {
          key: "remarks",
          label: "Remarks",
        },
        {
          key: "action",
          label: "Action",
        },
      ],
      currentPage: 1,
      selectedRow: {},
      showCollapse: false,
      limit:10,
      filter: {
        adjustment_id: "",
        booking_no: "",
        plate_no: "",
        destination: "",
        personnel_id: "",
        delivery_date_from: "",
        delivery_date_to: "",
        status: "",
      },

      personnelList: {
        data: [],
      },

      showViewModal: false,
     
      personnelLedgerParam: {
        entry_type : "",
        remarks: null,        
		    entry_date: null,
		    amount: null,
        is_deduction: null,
        id: null,
      },
      mode: {
        view: false,
        new: true,
        edit: false,
      },
      dataListHolder: [],
      date_from : null,
      date_to: null,
      salary : 0,
      salary_start_date: null,
      salary_end_date : null
    };
  },
  name: "Tables",
  components: { Search, Datepicker, vSelect },
  methods: {
   
    typeChanged(ev) {
      console.log(ev)
      if(ev == 'all') {
        this.dataList.data = [...this.dataListHolder];
      }
      else {
        this.dataList.data = this.dataListHolder.filter(item => item.entry_type_holder == ev)
      }
    },
    getLedgerInfo() {
      
      this.$showLoading(true)      
      axios.get(config.api_path+'/ledger/list/info',{
				params : {
					id : this.$route.params.id
				}
			}).then((res) => {
        const {data, salary, start_date, end_date} = res.data;
        this.$showLoading(false)  
          this.dataLedgerInfo = data;   
          this.salary = salary ?? 0;    
          this.salary_start_date = start_date;
          this.salary_end_date = end_date;                      
        })
        .catch(err => {
			this.$showLoading(false)
		}) 
    },
    download() {
			this.$showLoading(true)
			axios.get(config.api_path+'/ledger/view_download',{
				params: {id : this.$route.params.id}
			}).then(response => {
        console.log(response)
				this.$showLoading(false)
				window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 

					setTimeout(() => {
						axios.get(`${config.api_path}/remove-file`,{
							params : {
								filename : response.data.file
							}
						})	
					}, 2000);
				
			}).catch(err => {
				this.$showLoading(false)
			})
		},
		
   
  //add ledger entry
     // axios.post(config.api_path+'/ledger/process',{
    //   flag: 0, // 0 insert, 1 update, 2 delete
    //   type : 'CHARGES',
    //   amount : 50,
    //   date : '2022-09-28',
    //   remarks : 'deduct',
    //   is_deduction : 1,
    //   personnel_id : this.$route.params.id,
    //   id : null
    // })
    submit() {      
        if(this.personnelLedgerParam.amount == null || this.personnelLedgerParam.entry_date == null) {
          Swal.fire({
              title: 'warning',
              text: "Please fill out the fields.",
              icon: 'warning', 
            })	
        }
        this.$showLoading(true)      
        axios.post(config.api_path+'/ledger/process',{
          flag: this.personnelLedgerParam.id != null ? 1:0, // 0 insert, 1 update, 2 delete
          type : this.personnelLedgerParam.entry_type,
          amount : this.personnelLedgerParam.amount,
          date : moment(this.personnelLedgerParam.entry_date).format("y-M-D"),
          remarks : this.personnelLedgerParam?.remarks ?? null,
          is_deduction : this.personnelLedgerParam.is_deduction,
          personnel_id : this.$route.params.id,
          id : this.personnelLedgerParam.id,
          
        }).then(res => {
          this.$showLoading(false)
          this.formModal = false;
          this.deductionModal = false;
          this.getEntryList();
          this.getLedgerInfo();
          this.ResetLedgerParam();          	
        })
        .catch(err => {
          this.$showLoading(false)
        })  
        if(this.personnelLedgerParam.id != null) {
          Swal.fire({
              title: 'Success',
              text: "Entry successfully updated.",
              icon: 'success', 
            })	
        } else {
          Swal.fire({
              title: 'Success',
              text: "Entry successfully added.",
              icon: 'success', 
            })	           
        }        		       
    },

    addModal() {
      this.setMode("new");
      this.formModal = !this.formModal;
      this.title = "Add Entry";      
    },
    adddDeduction() {
      this.setModeDeduction("new");
      this.deductionModal = !this.deductionModal;
      this.title = "Deduction";
      this.personnelLedgerParam.is_deduction = 1;     
    },
    updateModal(item){
      console.log(item);
      this.title = "Update Entry";
      this.selectedRow = item;
      this.personnelLedgerParam = {...item};
      switch (this.personnelLedgerParam.entry_type) {
        case 'Charges':
              this.personnelLedgerParam.entry_type = 'charges';
        break;
        case 'Cash Advance':
              this.personnelLedgerParam.entry_type = 'cash_advance';
        break;
        case 'SSS Loan':
              this.personnelLedgerParam.entry_type = 'sss_loan';
        break;
        case 'Pagibig Loan':
              this.personnelLedgerParam.entry_type = 'pagibig_loan';
        break;
        case 'Other Deductions':
              this.personnelLedgerParam.entry_type = 'other_deductions';
        break;
      
       
      }
      // if(this.personnelLedgerParam.entry_type == "Charges") {
      //   this.personnelLedgerParam.entry_type = 'charges';
      // } else {
      //   this.personnelLedgerParam.entry_type = 'cash_advance';
      // }
      this.formModal = true;                 
    },
   
    setModeDeduction(type) {
      this.mode = {
        view: false,
        edit: false,
        new: false,
      };
      this.mode[type] = true;
    },
    setMode(type) {
      this.mode = {
        view: false,
        edit: false,
        new: false,
      };
      this.mode[type] = true;
    },
    
    viewPayment() {
    // this.$router.push('/data/odometer/'+this.$route.params.id)
		window.open(`#/main/view_personnel_payment/${this.$route.params.id}`, '_blank');
	},
	
  
   
    closeModal() {
      console.log("oyet");
      this.formModal = false;
      this.deductionModal = false;
      this.reloadData();
      this.ResetLedgerParam();
    },
    reloadData() {
      this.getEntryList();
    },
    updatePage(data) {
      this.currentPage = data;
      this.getEntryList();
    },

    // search(event) {
    //   this.filter = event;
    //   this.getEntryList();
    // },

    ResetLedgerParam() {                  
         this.personnelLedgerParam.entry_type = "",
         this.personnelLedgerParam.amount= "",
         this.personnelLedgerParam.entry_date= null,
         this.personnelLedgerParam.is_deduction = null,
         this.personnelLedgerParam.remarks = "",
         this.personnelLedgerParam.id = null
	},
   
    getPersonnel() {
      axios
        .get(config.api_path + "/reference/personnel-list")
        .then((response) => {
          this.personnelList = response.data;
        });
    },
    getEntryList() {
      this.$showLoading(true)
      axios.get(config.api_path+'/ledger/personnel/detail',{
      params : {
        personnel_id : this.$route.params.id,
        date_from : moment(this.date_from).format("y-M-D"),
        date_to : moment(this.date_to).format("y-M-D")
      }
    }).then((response) => {
      this.$showLoading(false)
        this.dataList = response.data;
        this.dataList.data = this.dataList.data.map(item => {
            item.entry_date = item.entry_date ? moment(item.entry_date ).format('MM/DD/YYYY') : '';
            item.entry_type_holder = item.entry_type;
            switch(item.entry_type) {
              case 'charges':
                item.entry_type = 'Charges';
                break;
              case 'cash_advance':
                item.entry_type = 'Cash Advance';
                break;
              case 'pagibig_loan':
                item.entry_type = 'Pag-ibig Loan';
                break;
              case 'sss_loan':
                item.entry_type = 'SSS Loan';
                break;
                case 'other_deductions':
                item.entry_type = 'Other Deductions';
                break;
            }
            // item.entry_type = item.entry_type == 'charges' ?  'Charges'  : 'Cash Advance';    
            item.amount = item.amount ? this.$options.filters.number_format(item.amount, '', 2) : '';                
            return item;
        });
        this.dataListHolder = [...this.dataList.data];        
    });   
     
      
    },
    // getEntryListFilter() {      
    //   axios.get(config.api_path+'/ledger/personnel/detail',{
    //   params : {
    //     personnel_id : this.$route.params.id,
    //     entry_type : this.dataParams.asset_type,
    //   }
    // }).then((response) => {
    //     this.dataList = response.data;
    //     this.dataList.data.filter(function(item){return this.dataParams.asset_type == item.entry_type});
                 
    // });      
    // },
    filterList() {
      this.getEntryList()
    },
    defaultDateFrom(){          
      var date = new Date();
      this.date_to = this.date = moment(date).format('DD MMM YYYY');
      date.setDate(date.getDate() - 30);
      this.date_from = this.date = moment(date).format('DD MMM YYYY');
      this.filterList();
    },
    deleteItem(item) {
      Swal.fire({
        icon: "warning",
        text: "Are you sure you want to delete this entry ?",
        confirmButtonText: `Yes, Delete.`,
        confirmButtonColor : 'red',
        reverseButtons: true,
        showCancelButton: true
      }).then((result) => {
        if (result.isConfirmed) {
          axios.post(config.api_path+'/ledger/process',{
            flag: 2, // 0 insert, 1 update, 2 delete
            type : item.type,
            amount : item.amount,
            date : item.date,
            remarks : item?.remarks ?? null,
            is_deduction : null,
            personnel_id : this.$route.params.id,
            id : item?.id ?? null,
            
          }).then(res => {
            this.$showLoading(false)
            this.formModal = false;
            this.getEntryList();
            this.getLedgerInfo();
            Swal.fire({
              title: 'Success',
              text: "Entry successfully deleted.",
              icon: 'success', 
            })	
          })
          .catch(err => {
            this.$showLoading(false)
          })          
        }
      });
    },
  },  
};


</script>
